import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import NewsSectionBlock from '../components/NewsSectionBlock'
import SEO from '../components/seo'
import Typography from '../components/Common/Typography'

import { PathEnum } from '../types/pages'
import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const ModularHomes: React.FC = () => {
  const { getPath } = useTranslations()
  return (
    <>
      <SEO
        title="What is a modular home and how to buy it in the UAE?"
        description="One of the cheap alternatives when buying real estate on the market right now is a modular home. In this article, we will consider the features, prices and builders of modular homes."
      />
      <NewsSectionBlock>
        <Typography variant="h1" color="blue">
          Modular homes in the UAE - pricing and builders
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/modular-homes-in-the-UAE/image-1.jpeg"
            alt="Modular home"
            title="Modular home"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          Modular homes are becoming more and more popular as days go by. People
          want to invest their money in these homes and so if you are thinking
          of buying such a home in the UAE, knowing how to go about it is a step
          in the right direction. So what are modular homes and why are people
          leaning towards them? Here’s all you need to know.
        </Typography>
        <Typography variant="h2" color="blue">
          What is a Modular Home?
        </Typography>
        <Typography variant="body1">
          It is a home that is built in a factory setting. Such a home is
          mass-produced and comes with a mix-and-match kind of deal. When you
          choose a <strong>modular home</strong> for yourself and order it, you
          will get all the supplies needed and instructions on how to put it all
          together.
        </Typography>
        <Typography variant="body1">
          A question you may wonder is,{' '}
          <strong>
            what is the difference between modular and prefab homes
          </strong>
          ? The answer is, there is absolutely no difference. They mean one and
          the same thing. At one point in time, these were the kind of homes
          that were stigmatized because they were considered cheap. But not
          anymore. Such homes can be a lot more luxurious and comfortable than
          most people think.
        </Typography>
        <Typography variant="body1">
          Modern BIM technologies allow you to build your house from ready-made
          blocks, like Lego parts. Сompleted drone photography will enable you
          to land the future building on the site in a matter of minutes and
          then quickly select the orientation to the cardinal points. SIGNAX
          specializes in{' '}
          <Link to={getPath(PathEnum.Bim)} className="has-text-primary">
            BIM consulting services
          </Link>{' '}
          and{' '}
          <Link to={getPath(PathEnum.Digital)} className="has-text-primary">
            digital measurements
          </Link>{' '}
          using laser scanning and drones.
        </Typography>
        <Typography variant="h2" color="blue">
          Modular Home Builders and Manufacturers in the UAE
        </Typography>
        <Typography variant="body1">
          The UAE is a country that has caught up in it’s interest in{' '}
          <strong>modern modular homes</strong>. The reason is that people here
          love luxury in how they live and most don’t mind paying for it. You
          will see a lot of <strong>small modular homes</strong> just for this
          reason. Let’s look at some of the reasons why such homes are a popular
          choice in the UAE:
        </Typography>
        <ol className={`${s.textLists} ${s.decimal}`}>
          <li>
            <Typography variant="body1">
              <strong>Less Expensive</strong>
            </Typography>
            <Typography variant="body1">
              You cannot ignore the fact that{' '}
              <strong>designer modular homes</strong> are much less expensive
              than stick-built ones. This makes them rather attractive to most
              of the population. Such homes are made in a way so that they are
              easy to assemble, and so you will have to pay a much lesser cost
              for the labour.
            </Typography>
            <Typography variant="body1">
              Don’t forget that with most{' '}
              <strong>modular homes for sale</strong>, you get access to pre-cut
              items, which is great. Everything gets delivered to the site on
              time, so the act of putting up your house is done quickly.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>High-Quality</strong>
            </Typography>
            <div className={s.imageWrap}>
              <StaticImage
                width={720}
                src="../assets/images/articles/modular-homes-in-the-UAE/image-2.jpeg"
                alt="Modular home 2"
                title="Modular home 2"
                placeholder="blurred"
              />
            </div>
            <Typography variant="body1">
              When you choose <strong>modular homes for sale</strong> from a
              reputed company, you get a high-quality product that has passed
              through stringent quality control. There was a time when people
              used to expect cruddy quality stuff when they ordered a modular
              product, but that has changed. You can expect a well-made
              high-quality product.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Quickly Setup</strong>
            </Typography>
            <Typography variant="body1">
              With traditional homes, it can take months and even years to have
              one built. But today <strong>modular home builders</strong> have
              access to great raw materials and technologies that make the setup
              really quick and easy.
            </Typography>
            <Typography variant="body1">
              People who want to actually build a home in a bit of a rush can
              choose this way of going forward. It will be built quickly and you
              will get an effective home at the end of the process.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Remote demonstration</strong> - using modern photo360
              services (for example,{' '}
              <Link
                to={getPath(PathEnum.Inspection)}
                className="has-text-primary"
              >
                SIGNAX INSPECTION
              </Link>{' '}
              module). You can remotely visit your factory-assembled home, even
              before payment is made, and then accept it after on-site
              installation. Also, you can easily compare that everything
              corresponds 1 to 1.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Eco-friendly Designs</strong>
            </Typography>
            <Typography variant="body1">
              Another big benefit that <strong>modular home builders</strong>{' '}
              put forward is that they have several eco-friendly designs. You
              may want a home that is highly energy-efficient and it is possible
              to get it when you choose one of the{' '}
              <strong>modular home manufacturers</strong> for the same.
            </Typography>
          </li>
        </ol>
        <Typography variant="h2" color="blue">
          What Affects Modular Home Prices?
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/articles/modular-homes-in-the-UAE/image-3.jpeg"
            alt="Modular home 3"
            title="Modular home 3"
            placeholder="blurred"
          />
        </div>
        <Typography variant="body1">
          This is a question that buyers wonder about. When you are in the
          market for modular homes, knowing what is going to affect the prices
          will certainly help you make the right decision.
        </Typography>
        <ul className={`${s.textLists}`}>
          <li>
            <Typography variant="body1">
              <strong>Floor Plan</strong>: This undoubtedly matters a lot when
              talking about{' '}
              <strong>modular homes'floor plans and prices</strong>. What type
              of flooring plan are you looking for? The number of bedrooms you
              want, and the number of floors you need, are important factors as
              to the price. It can be said that the higher number of bedrooms
              you need, the higher the price will be.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Size</strong>: he size of the modular homes for sale is
              another important consideration. The bigger the size of the home
              you want, the higher the price is going to be.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Customisation</strong>: The next factor that will decide
              modular home pricing is the customization you require. Standard
              home pricings are going to be a lot cheaper than if you were to
              order something that is customized to your needs. If there are
              extra amenities or structures you are going to require, that is
              going to add to the final cost.
            </Typography>
            <Typography variant="body1">
              The thing to understand here is that often{' '}
              <strong>modular home builders</strong> will have templates
              available for consumers to buy and even a small change like the
              placement of a window or a door different than what is in the
              template is going to add to the price. Keep these in mind when you
              order such a home for yourself.
            </Typography>
          </li>
          <li>
            <Typography variant="body1">
              <strong>Delivery Costs</strong>: Where your piece of land is
              located is another factor that will affect the price. The further
              away your property is, the higher it will cost for the
              manufacturer to ship the product.
            </Typography>
          </li>
        </ul>
        <Typography variant="h3">Building a Home in the UAE</Typography>
        <Typography variant="body1">
          With rising prices on all items, whether it is fuel or groceries,
          cutting down costs should not mean cutting down on your dreams. If you
          are planning to build a home in the UAE, then going the modular way
          can be a great idea. Don’t worry about modular homes all looking the
          same, because they don’t have to. With dedication and intelligence,
          you will be able to find a builder who invests in what the best
          technology has to offer. Quickly build your home in the way that you
          envisioned because that is what matters. Get the latest in luxury or
          something basic: whatever it is that your heart wants at this
          particular point in life!
        </Typography>
        <Typography variant="body1">
          If you are the customer of the modular homes, the best decision is to
          implement SIGNAX platform for the project ecosystem. You will have the
          unified functionality to control the project and each contractor:
          understand the actual progress against the plan, perform a remote site
          inspection and reduce the time needed to make a management decision.
          It’s a win-win situation in every way for you as a owner!
        </Typography>
      </NewsSectionBlock>
    </>
  )
}

export default ModularHomes
